import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A drug that you feed off of,`}<br parentName="p"></br>{`
`}{`a part of you,`}<br parentName="p"></br>{`
`}{`An extent of your body.`}<br parentName="p"></br>{`
`}<em parentName="p">{`One`}</em></p>
    <p>{`A leech;`}<br parentName="p"></br>{`
`}{`Nagging you,`}<br parentName="p"></br>{`
`}{`Eating you,`}<br parentName="p"></br>{`
`}<em parentName="p">{`Stealing you.`}</em></p>
    <p>{`A feeling that you seek,`}<br parentName="p"></br>{`
`}{`A need for it,`}<br parentName="p"></br>{`
`}{`A requirement for it,`}<br parentName="p"></br>{`
`}{`Seeking the warmth it provides.`}</p>
    <p>{`Feeling the flame against your face,`}<br parentName="p"></br>{`
`}{`Allowing it to consume you.`}<br parentName="p"></br>{`
`}{`It’s all you are now.`}<br parentName="p"></br>{`
`}{`The only thing keeping you from going under.`}<br parentName="p"></br>{`
`}{`Without it,`}<br parentName="p"></br>{`
`}{`You are alone.`}<br parentName="p"></br>{`
`}{`Lost without the torch to guide you.`}<br parentName="p"></br>{`
`}{`Empty,`}<br parentName="p"></br>{`
`}{`Spinning in circles,`}<br parentName="p"></br>{`
`}{`Hopelessly looking for the light.`}</p>
    <p>{`Desperation,`}<br parentName="p"></br>{`
`}{`Attachment,`}<br parentName="p"></br>{`
`}{`Devotion,`}<br parentName="p"></br>{`
`}{`Commitment.`}</p>
    <p>{`This is passion.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      